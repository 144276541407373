import { reclaimEditionComparitor } from "../utils/sort";
import { ProductUsageReport as ProductUsageReportDto } from "./client";
import { dtoToEntitlementTableRow, dtoToReclaimEditionStr } from "./team/Team.mutators";
import { ProductUsageReport } from "./Users.types";

export const dtoToProductUsageReport = (dto: ProductUsageReportDto): ProductUsageReport => {
  const usageEdition = dtoToReclaimEditionStr(dto.usageEdition);
  const currentEdition = dtoToReclaimEditionStr(dto.currentEdition);

  return {
    usageEdition,
    currentEdition,
    entitlementsWithActuals: dtoToEntitlementTableRow<true>(dto.entitlementsWithActuals),

    // TODO: this are mocked, but should be handled on the server -SG
    usageOverage: reclaimEditionComparitor(usageEdition, currentEdition) > 0,
  };
};
